import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import { useTranslation } from "react-i18next";
import { SectionWrapper, SectionHeader } from "../visuella.style";
import GatsbyImage from "common/components/GatsbyImage";
import Masonry from "react-masonry-component";
import { AppsWrapper, AppItem, AppItemInner } from "./apps.style";
import { Chip, Stack } from "@mui/material";
import {
  FaReact,
  FaCode,
  FaJs,
  FaAws,
  FaNodeJs,
  FaAndroid,
  FaApple,
  FaStripe,
} from "react-icons/fa";
import { SiFlutter, SiFirebase, SiHasura } from "react-icons/si";
import { rgba } from "polished";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Link from "common/components/Link";
import { Icon } from "react-icons-kit";
import PropTypes from "prop-types";

const masonryOptions = {
  originTop: true,
};

const Apps = ({ isLatest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allVisuellaJson {
          nodes {
            apps {
              title
              subtitle_en
              subtitle_fr
              link
              tags
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  );
  const { apps } = data.allVisuellaJson.nodes[0];

  const filteredApps = apps.slice(0, isLatest ? 4 : apps.length);

  const { t, i18n } = useTranslation();

  const getTag = (tag) => {
    let backgroundColor;
    let color;
    let icon;
    switch (tag) {
      case "React":
        backgroundColor = rgba(144, 205, 244, 0.2);
        color = rgba(44, 105, 144, 1);
        icon = <FaReact color={color} />;
        break;
      case "Flutter":
        backgroundColor = rgba(144, 205, 244, 0.2);
        color = rgba(44, 105, 144, 1);
        icon = <SiFlutter color={color} />;
        break;
      case "Javascript":
        backgroundColor = rgba(250, 240, 137, 0.2);
        color = rgba(150, 140, 37, 1);
        icon = <FaJs color={color} />;
        break;
      case "nodeJs":
        backgroundColor = rgba(154, 230, 180, 0.2);
        color = rgba(54, 130, 80, 1);
        icon = <FaNodeJs color={color} />;
        break;
      case "Firebase":
        backgroundColor = rgba(251, 211, 141, 0.2);
        color = rgba(151, 111, 41, 1);
        icon = <SiFirebase color={color} />;
        break;
      case "Aws":
        backgroundColor = rgba(250, 240, 137, 0.2);
        color = rgba(150, 140, 37, 1);
        icon = <FaAws color={color} />;
        break;
      case "Android":
        backgroundColor = rgba(154, 230, 180, 0.2);
        color = rgba(54, 130, 80, 1);
        icon = <FaAndroid color={color} />;
        break;
      case "iOS":
        backgroundColor = rgba(226, 232, 240, 0.2);
        color = rgba(126, 132, 140, 1);
        icon = <FaApple color={color} />;
        break;
      case "Stripe":
        backgroundColor = rgba(214, 188, 250, 0.2);
        color = rgba(114, 88, 150, 1);
        icon = <FaStripe color={color} />;
        break;
      case "Hasura":
        backgroundColor = rgba(154, 230, 180, 0.2);
        color = rgba(54, 130, 80, 1);
        icon = <SiHasura color={color} />;
        break;
      default:
        backgroundColor = rgba(226, 232, 240, 0.2);
        color = rgba(126, 132, 140, 1);
        icon = <FaCode color={color} />;
        break;
    }
    let iconSize = 14;
    if (typeof window !== "undefined") {
      iconSize = window.innerWidth <= 768 ? 12 : 14;
      icon = React.cloneElement(icon, { size: iconSize });
    }
    return {
      backgroundColor: backgroundColor,
      color: color,
      icon: icon,
    };
  };

  return (
    <SectionWrapper
      id="apps"
      style={{
        "--paddingTop": isLatest ? "10px" : "100px",
        "--paddingBottom": "60px",
      }}
    >
      <Container>
        <SectionHeader>
          <Heading
            as="h5"
            content={t(isLatest ? "apps.latestAppsTitle" : "apps.title")}
          />
          <Heading content={t(`apps.subtitle`)} />
        </SectionHeader>
        <AppsWrapper>
          <Masonry className="masonryGrid" options={masonryOptions}>
            {filteredApps.map(
              ({ title, subtitle_en, subtitle_fr, link, tags, image }, index) => (
                <AppItem key={index}>
                  <AppItemInner>
                    <GatsbyImage
                      src={
                        (image !== null) | undefined
                          ? image.childImageSharp.gatsbyImageData
                          : {}
                      }
                      alt={title}
                      style={{ borderRadius: "10px 10px 0 0" }}
                    />
                    <Heading as="h3" content={title} />

                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        scrollBehavior: "smooth",
                        paddingLeft:
                          typeof window !== "undefined"
                            ? window.innerWidth <= 768
                              ? "0.2rem"
                              : "1rem"
                            : "1rem",
                        paddingRight:
                          typeof window !== "undefined"
                            ? window.innerWidth <= 768
                              ? "0.2rem"
                              : "1rem"
                            : "1rem",
                        scrollbarWidth: "none",
                      }}
                      className="no-scrollbar"
                    >
                      {tags.map((tag, tagIndex) => (
                        <Chip
                          key={tagIndex}
                          icon={getTag(tag).icon}
                          label={tag}
                          className="tag"
                          style={{
                            backgroundColor: `${getTag(tag).backgroundColor}`,
                            color: `${getTag(tag).color}`,
                            fontSize:
                              typeof window !== "undefined"
                                ? window.innerWidth <= 768
                                  ? "10px"
                                  : "12px"
                                : "12px",
                            fontWeight: "bold",
                            padding:
                              typeof window !== "undefined"
                                ? window.innerWidth <= 768
                                  ? "1px 0.5px"
                                  : "6px 2px"
                                : "6px 2px",
                            height:
                              typeof window !== "undefined"
                                ? window.innerWidth <= 768
                                  ? "18px"
                                  : "auto"
                                : "auto",
                            marginRight: "0.5rem",
                          }}
                        />
                      ))}
                    </div>
                    <hr className="line" />
                    <Text content={i18n.language === "en" ? subtitle_en : subtitle_fr} style={{ display: '-webkit-box', WebkitLineClamp: 5, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }} />
                  </AppItemInner>
                </AppItem>
              )
            )}
          </Masonry>
        </AppsWrapper>
        <SectionHeader>
          {isLatest ? (
            <Link href="/apps" className="view-all">
              {t("apps.viewAll")}
              <Icon icon={arrowRight} className="view-all-icon" />
            </Link>
          ) : null}
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Apps.propTypes = {
  isLatest: PropTypes.bool,
};

Apps.defaultProps = {
  isLatest: true,
};

export default Apps;
