import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const AppsWrapper = styled.div`
  padding-top: 10px;
  @media only screen and (max-width: 991px) {
    padding-top: 0px;
  }
  .masonryGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`;

export const AppItem = styled.div`
  width: calc(100% / 2);
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 12px;
  }
  @media only screen and (max-width: 624px) {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 12px;
    width: 100%;
  }
`;

export const AppItemInner = styled.div`
  position: relative;
  background-color: ${themeGet("colors.white")};
  border-radius: 10px 10px 0 0;
  padding-bottom: 2px;
  box-shadow: ${themeGet("colors.blueShadowColor")} 0px 15px 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${themeGet("colors.blueShadowColor")} 0px 20px 60px,
      ${themeGet("colors.visuellaPrimary")} 0px 0px 0px 3px;
  }
  .line {
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid ${themeGet("colors.lineColor")};
  }
  h3 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 18px;
  }
  p {
    color: ${themeGet("colors.textColor")};
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    > span {
      color: ${themeGet("colors.visuellaPrimary")};
      font-weight: 500;
    }
    @media only screen and (max-width: 991px) {
      font-size: 11px;
      line-height: 12px;
    }
    @media only screen and (max-width: 624px) {
      font-size: 11x;
      line-height: 12px;
    }
  }
`;
